import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const EXT_ID = 'olljnkilmblncgcghhaodkpdcnokhpah'
//const EXT_ID = 'mopfdhgfjmeomgpfpeggpokbleedodmm'
export const fetchResult = async () => {
  try {
    chrome.runtime.connect(EXT_ID)
    const response = await chrome.runtime.sendMessage(EXT_ID, {
      action: 'GetResults',
      value: 0,
    })
    if (!response.payload) {
      throw new Error('Failed to fetch users')
    }
    return response.payload
  } catch (error) {
    console.log(error, 'myerror')
    throw error
  }
}

export const updateResult = async () => {
  try {
    chrome.runtime.connect(EXT_ID)
    const response = await chrome.runtime.sendMessage(EXT_ID, {
      action: 'UpdateResults',
      value: 0,
    })
    if (!response.result === 'updated') {
      throw new Error('Failed to fetch users')
    }
    return response.payload
  } catch (error) {
    console.log(error, 'myerror')
    throw error
  }
}

export const fetchExtensionAsync = createAsyncThunk('extension/fetchResult', async () => {
  const response = await fetchResult()
  console.log('my res', response)
  return response
})
export const updateExtensionAsync = createAsyncThunk('extension/updateResult', async () => {
  const response = await updateResult()
  console.log('my res', response)
  return response
})

const extensionSlice = createSlice({
  name: 'extension',
  initialState: {
    result: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExtensionAsync.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchExtensionAsync.fulfilled, (state, action) => {
        state.loading = false
        state.result = action.payload
      })
      .addCase(fetchExtensionAsync.rejected, (state, action) => {
        state.loading = false
        console.warn(action.error.message)
        state.error = action.error.message
      })
  },
})

export default extensionSlice.reducer
