import { configureStore } from '@reduxjs/toolkit'
import { usersApi } from './apiSlice'
import extensionReducer from './extensionSlice'
import themeSlice from './themeSlice'
import authSlice from './authSlice'

const store = configureStore({
  reducer: {
    [usersApi.reducerPath]: usersApi.reducer,
    auth: authSlice,
    extension: extensionReducer,
    theme: themeSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(usersApi.middleware)
  },
})
//setupListeners(store.dispatch)

//const store = createStore(changeState)
export default store
