import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  token: null,
  licence: 'FREE',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.token = action.payload
    },
    setLicence: (state, action) => {
      state.licence = action.payload
    },
  },
})

export const { setAuthToken, setLicence } = authSlice.actions

export default authSlice.reducer
